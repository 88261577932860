import React from "react";
import clsx from "clsx";
import { ColumnItem, Accordion } from "@molecules";
import { Text, Container, Button } from "@atoms";
import RibbonsThree from "@svg/RibbonsThree";

const TwoColumn = ({
  bgColor,
  button,
  className: _className,
  hideRibbons,
  items,
  paddingBottom,
  paddingTop,
  position,
  subheading,
}) => {
  const buttonColors = {
    midnight: "carnation",
    carnation: "midnight",
    mint: "midnight",
  };

  const ribbonColors = {
    midnight: "mint",
    carnation: "white",
    white: "carnation",
  };

  const sortedItems = items.reduce(
    (arr, item) => {
      item.type === "accordion" ? arr[1].push(item) : arr[0].push(item);
      return arr;
    },
    [[], []]
  );

  return (
    <section
      className={clsx(
        "relative z-20",
        {
          "bg-midnight": bgColor === "midnight",
          "bg-carnation": bgColor === "carnation",
          "py-16": hideRibbons,
          "md:pt-32": hideRibbons && paddingTop,
          "md:pb-32": hideRibbons && paddingBottom,
          "pb-16 md:pb-32": !hideRibbons && position === "top",
          "pt-16 md:pt-32": !hideRibbons && position === "bottom",
        },
        _className
      )}
    >
      {!hideRibbons && position === "top" && (
        <RibbonsThree
          color={ribbonColors[bgColor]}
          className="relative inset-x-0 z-10 pb-8 md:pb-16"
        />
      )}
      <Container className="relative z-20">
        <div
          className={clsx(
            "columns-1 gap-16 [column-rule-width:2px] [column-rule:solid] md:columns-2 md:gap-32",
            {
              "[column-rule-color:#FFFFFF]": bgColor === "carnation",
              "[column-rule-color:#9BFCD9]": bgColor === "midnight",
            }
          )}
        >
          {!!sortedItems[0].length && (
            <div
              className={clsx({
                "break-inside-avoid": !!sortedItems[1].length,
              })}
            >
              {sortedItems[0].map((item, i) => {
                const { type, uid } = item;
                switch (type) {
                  case "button":
                    return (
                      <div
                        className={clsx({
                          "mt-8": i !== 0,
                        })}
                      >
                        <Button
                          to={item.link?.url}
                          color={buttonColors[bgColor]}
                        >
                          {item.link?.text || "Learn More"}
                        </Button>
                      </div>
                    );
                  case "copy":
                    return (
                      <Text
                        key={uid}
                        richText
                        className={clsx("block", {
                          "bold-mint prose-headings:text-white prose-p:text-white":
                            bgColor === "midnight",
                          "bold-midnight prose-headings:text-white":
                            bgColor === "carnation",
                          "mt-8": i !== 0 || subheading,
                        })}
                      >
                        {item.copy}
                      </Text>
                    );
                  case "figure":
                    return (
                      <ColumnItem
                        key={uid}
                        color={bgColor}
                        className={clsx({
                          "mt-16": i !== 0,
                        })}
                        transparent
                        {...item}
                      />
                    );
                  default:
                    return <div>{type}</div>;
                }
              })}
            </div>
          )}
          {!!sortedItems[1].length && (
            <div className="mt-8 break-inside-avoid space-y-8">
              {sortedItems[1]?.map(item => {
                const { uid } = item;
                return <Accordion key={uid} bgColor={bgColor} {...item} />;
              })}
            </div>
          )}
        </div>
        {button?.url && (
          <div className="mt-16 flex items-center justify-center">
            <Button to={button.url} color={buttonColors[bgColor]}>
              {button.text || "Learn More"}
            </Button>
          </div>
        )}
      </Container>
      {!hideRibbons && position === "bottom" && (
        <RibbonsThree
          color={ribbonColors[bgColor]}
          className="relative inset-x-0 z-10 pt-8 md:pt-16"
        />
      )}
    </section>
  );
};

TwoColumn.defaultProps = {
  hideRibbons: false,
  paddingBottom: false,
  paddingTop: false,
  position: "top",
};

export default TwoColumn;
